import React from 'react';

export function FourOhFour() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 276 137">
      <path
        fill="currentColor"
        d="M.6 95.3V11.2h18.2v66h36.4v-66h18.2V134H55.2V95.3H.6zM137 136.4c-28.2 0-47-15.9-47-39.7C90 72 114.3 70.1 114.3 51c0-20.5-18.9-25.2-18.9-39.8 0-6.6 4.2-11 10.5-11 12 0 29.6 18.2 32.2 18.2 2.6 0 20.3-18.2 32.2-18.2 6.3 0 10.5 4.4 10.5 11 0 14.6-18.9 19.3-18.9 39.8 0 19.2 26 21.1 26 45.8 0 23.8-20.4 39.7-50.8 39.7zm.8-111.4c-5.6 0-17.5 5.6-17.5 14.1 0 11.3 13.2 11.8 13.2 19.6 0 11.4-19.6 10.4-19.6 35.8 0 15.9 9.4 26.5 23.4 26.5 16.1 0 26.8-10.6 26.8-26.5 0-25.4-21.4-24.4-21.4-35.8 0-7.8 13.2-8.4 13.2-19.6 0-8.5-11.4-14.1-18-14.1zM202.6 95.3V11.2h18.2v66h36.4v-66h18.2V134h-18.2V95.3h-54.6z"
      />
    </svg>
  );
}

export function FourOhFourGraphic() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 512 800">
      <defs />
      <g clipPath="url(#clip0)">
        <path
          fill="#FF4632"
          d="M237.3 656.3c-17.6-6.2-21.2-2.2-13.3 14.6-15.9-9.7-20.1-6.6-15.6 11.4-13.5-12.7-18.2-10.5-17.1 8-10.8-15-15.8-13.8-18.3 4.6-7.7-16.9-12.8-16.6-18.7 1-4.4-18-9.6-18.8-18.8-2.7-.9-18.5-5.9-20.3-18.2-6.3 2.7-18.4-2-21.2-17-10.1 6-17.7 2-21.3-14.7-13.4 9.7-15.9 6.6-20.1-11.4-15.5 12.7-13.6 10.5-18.3-8-17.2 15-10.8 13.8-15.8-4.5-18.2 16.8-7.8 16.5-12.9-1-18.8 18-4.4 18.7-9.5 2.6-18.8 18.5-.8 20.3-5.8 6.4-18.1 18.4 2.7 21.1-2 10-17.1 17.7 6.1 21.4 2.1 13.5-14.7 15.8 9.7 20 6.6 15.4-11.4 13.6 12.7 18.3 10.5 17.2-8 10.8 15.1 15.8 13.9 18.2-4.5 7.8 16.9 13 16.6 18.8-1 4.4 18 9.5 18.7 18.8 2.6.9 18.6 5.9 20.3 18.2 6.4-2.7 18.4 2 21.2 17 10.1-6 17.6-2 21.3 14.7 13.4-9.7 15.9-6.6 20.1 11.4 15.5-12.7 13.6-10.5 18.3 8 17.2-15 10.8-13.8 15.8 4.6 18.2-17 7.8-16.6 12.9 1 18.8-18 4.4-18.8 9.5-2.7 18.8-18.5.8-20.3 5.8-6.3 18.2-18.4-2.7-21.2 2-10.2 17z"
        />
        <path
          fill="#000"
          d="M106.6 563.3l-6 8 22.5 16.7-3.6 4.8L135 604l3.6-4.7 6.5 4.8 6.9-9.2-6.6-4.8 11.9-16.2-17.2-12.7-33.4 2zm23.3 15.5l-9.6-7 15.5-1-6 8zM170 590.5c2-2.8 2-5.5-.8-7.6l-.6-.4c-2.8-2.1-5.4-1.3-7.5 1.5l-15.7 21.3c-2.1 2.8-1.9 5.5.8 7.5l.6.4c2.6 2 5.2 1.5 7.4-1.4l15.7-21.3zm-20.1 18.7c-.3.4-.5.5-.8.3-.3-.2-.2-.5 0-.9l16.4-22.1c.3-.4.5-.6.9-.3.3.2.2.5-.1.8l-16.4 22.2zM161.3 603.8l-6 8 22.5 16.7-3.6 4.7 15.4 11.4 3.6-4.8 6.6 5 6.8-9.3-6.6-4.9 12-16-17.3-12.8-33.4 2zm23.3 15.5l-9.5-7.1 15.4-1-6 8z"
        />
        <path
          fill="#B19CCA"
          d="M239.4 207c-54.2-28.8-55.3-27.2-10.6 14.7-47.4-39-48.8-37.7-13 11.8-39-47.2-40.7-46.2-14.9 8.9-29.2-53.4-31-52.8-16.3 5.7-18.5-57.6-20.4-57.3-17.2 2.6-7-59.6-9-59.8-17.6-.7 4.6-59.6 2.7-60-17.6-4.1 16.4-57.2 14.6-58.1-17-7.7 27.9-52.6 26.2-53.7-15.2-10.9 38.3-45.5 37-47-12.3-13.1 46.8-37 45.8-38.7-9.5-15 53.5-27.1 52.8-29-6.3-16.1 58-16.6 57.7-18.5-3.2-17 60.5-5.3 60.6-7.2.3-17.4 60.7 6.3 61.2 4.4 3.7-17.1 58.7 17.8 59.5 16 7.4-16.5 54.2 28.9 55.3 27.3 10.6-14.6 47.4 39 48.8 37.6 13-11.8 39 47.1 40.6 46.1 14.8-8.9 29.3 53.4 31.1 52.7 16.3-5.8 18.5 57.6 20.5 57.3 17.3-2.6 7 59.7 9 59.8 17.6.7-4.7 59.6-2.8 60.1 17.5 4.2-16.4 57.2-14.5 58 17 7.7-27.8 52.5-26.1 53.7 15.2 10.8-38.3 45.6-36.9 47 12.4 13.2-46.9 37-45.8 38.7 9.4 14.9-53.4 27.2-52.7 29 6.4 16.2-58 16.5-57.7 18.4 3.1 17-60.5 5.2-60.6 7.2-.2 17.3-60.8-6.3-61.2-4.4-3.7 17.2-58.7-17.8-59.5-16-7.4 16.5z"
        />
        <path
          fill="#000"
          d="M138.8 169.2v4.7h4.9v8.2h5.6v-8.2h1.8v-5.4h-1.8v-31.2h-5.8c-1.4 8.4-3.4 23.2-4.7 32zm4.9-.7h-1.4c.5-6.7 1.2-12 1.4-16v16zM164.9 144c0-4.2-1.9-6.9-6.2-6.9h-.8c-4.3 0-6.2 2.6-6.2 6.8v31.8c0 4.2 2.2 6.7 6.2 6.7h.8c4 0 6.2-2.4 6.2-6.7V144zm-6 32.3c0 .5-.2.9-.6.9-.5 0-.6-.4-.6-1v-33c0-.6.1-.9.6-.9s.6.4.6.9v33zM165.6 169.2v4.7h4.9v8.2h5.6v-8.2h1.8v-5.4h-1.8v-31.2h-5.8c-1.4 8.4-3.4 23.2-4.7 32zm4.9-.7H169c.5-6.7 1.2-12 1.4-16v16z"
        />
        <path
          fill="#FAE62D"
          d="M592.3 320.9c-19.3 21-16 28.5 12.5 27.7-23.3 16.3-21.6 24.2 6.5 28.8-26.1 11.2-26 19.2.7 29-27.7 5.9-29 13.7-4.7 28.3-28.4.6-31.2 8-10.1 27-28-5-32.1 1.9-15.1 24.7-26.6-10.1-32-4.2-20 21.6-24.3-15-31-10-24.7 17.8-21-19.3-28.5-16-27.7 12.5-16.4-23.3-24.2-21.6-28.8 6.5-11.3-26.1-19.2-26-29 .7-5.9-27.7-13.6-29-28.3-4.8-.5-28.3-7.9-31-27-10 5-28-1.8-32.1-24.7-15.2 10.1-26.5 4.2-32-21.5-20 15-24.2 10-31-17.9-24.6 19.4-21 16-28.6-12.4-27.7 23.3-16.4 21.5-24.2-6.5-28.7 26-11.3 25.9-19.2-.8-29 27.8-6 29-13.7 4.8-28.4 28.4-.5 31.1-7.9 10-27 28 5 32.2-1.8 15.2-24.6 26.5 10 32 4 20-21.7 24.3 15 31 10.1 24.6-17.8 21 19.4 28.6 16 27.8-12.4 16.3 23.3 24.2 21.5 28.7-6.5 11.3 26 19.3 25.8 29-.8 6 27.8 13.8 29 28.4 4.8.5 28.3 8 31 27 10-5 28 1.9 32.1 24.7 15.2-10.1 26.5-4.2 32 21.6 20-15 24.1-10.1 30.9 17.7 24.6z"
        />
        <path
          fill="#000"
          d="M391.6 461.5l-28.9-50 10.8-6.3 22.6 39.2 21.7-12.5-22.7-39.1 10.9-6.3 42 73-10.7 6.2-13.3-23-32.4 18.8zM486.7 439.1c-16.8 9.7-33.4 6.7-41.5-7.5-8.5-14.6 5.2-24-1.4-35.5-7-12.1-19.9-8.5-24.8-17-2.3-4-1.3-8 2.4-10.2 7-4.1 23.8.6 25.4-.3 1.5-.9 5.8-17.7 12.9-21.8 3.7-2.2 7.7-1 10 3 5 8.6-4.7 17.9 2.4 30 6.6 11.4 22.6 3.7 31 18.4 8.2 14 1.6 30.5-16.4 41zM449 372.6c-3.4 2-8.5 9.4-5.6 14.5 3.9 6.6 11.8 2.4 14.5 7.1 4 6.8-8 12.9.6 28 5.5 9.4 14.8 12.5 23 7.7 9.6-5.5 12.3-15.5 6.9-25-8.7-15-21-7.1-25-13.9-2.7-4.6 5-9.5 1.1-16.1-3-5.1-11.6-4.5-15.5-2.3zM511.5 392.2l-28.8-50 10.8-6.2 22.6 39.2 21.6-12.5-22.6-39.2 10.8-6.2 42.1 73-10.8 6.2-13.2-23-32.5 18.7z"
        />
        <path
          fill="#1FC58E"
          d="M451.8 814c-2.6 3.2-18.7-7-21.7-4.5-3.1 2.5 4 20.1.5 22-3.4 1.8-14-13.9-17.7-12.8-3.7 1.1-3.8 20.1-7.6 20.5-3.8.4-7.8-18.1-11.6-18.5-3.8-.3-11 17.3-14.7 16.2-3.7-1-.5-19.8-4-21.6-3.5-1.8-17 11.5-20.3 8.9-3.2-2.6 7-18.6 4.5-21.7-2.5-3-20.1 4-22 .6-1.8-3.4 14-14.1 12.8-17.8-1.1-3.7-20-3.7-20.5-7.5-.4-3.8 18.1-7.8 18.5-11.6.3-3.8-17.3-11-16.2-14.8 1-3.7 19.8-.5 21.6-4 1.8-3.5-11.5-17-8.9-20.3 2.6-3.1 18.6 7 21.7 4.5 3-2.5-4-20-.6-22 3.4-1.7 14.1 14 17.8 12.8 3.7-1 3.7-20 7.5-20.4 3.8-.5 7.8 18 11.6 18.4 3.9.4 11-17.2 14.8-16.1 3.7 1 .5 19.7 4 21.6 3.5 1.8 17-11.5 20.3-9 3.2 2.6-7 18.7-4.5 21.7 2.5 3.1 20-4 22-.5 1.7 3.4-14 14-12.8 17.7 1 3.7 20 3.8 20.5 7.6.4 3.8-18.2 7.8-18.5 11.6-.3 3.8 17.2 11 16.1 14.7-1 3.7-19.7.5-21.6 4-1.8 3.5 11.5 17.1 9 20.3z"
        />
        <path
          fill="#000"
          d="M372.3 765.7l4.7 2 2-4.5c-2.5 2.2-4.6 1.8-6 1.2l6.1-13.9-3.7-1.6-15 11.5 6.9 3-.8 1.8c-.8 1.8-1.8 2.5-2.8 2.6l7.8 3.5c-.6-.8-.8-2 0-3.8l.8-1.8zm0-13.4l-4.4 9.8-5.4-2.4 9.8-7.4zM414.7 777c1.9-4.3 2.8-11.8-11.6-18.2l-1.4-.6c-14.4-6.4-19.3-.7-21.2 3.5l-.4.9c-2 4.2-2.8 11.8 11.5 18.2l1.4.6c14.4 6.4 19.4.7 21.3-3.6l.4-.8zm-11.7-4.6c-.8 1.8-3.2 3.7-7.6 1.7-4.4-2-4.6-5-3.7-6.8l.1-.3c.8-2 3-3.6 7.5-1.6 4.4 2 4.7 4.7 3.9 6.6l-.2.4zM424.4 788.9l4.6 2 2-4.6c-2.4 2.2-4.5 1.9-6 1.2l6.1-13.8-3.6-1.6-15 11.4 6.8 3.1-.8 1.8c-.8 1.8-1.8 2.5-2.8 2.6l7.8 3.5c-.5-.8-.7-2 .1-3.9l.8-1.7zm-.1-13.5l-4.4 9.9-5.4-2.4 9.8-7.5z"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0h512v800H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
