import React, { useContext, useEffect } from 'react';
import { Link } from 'gatsby';
import 'css/404.css';

import ThemeContext, { THEMES } from 'common/context.theme';
import SEO from 'components/seo';
import { FourOhFour, FourOhFourGraphic } from 'components/svgs/fourohfour';

const NotFoundPage = () => {
  const { setTheme } = useContext(ThemeContext);
  useEffect(() => setTheme(THEMES.dark), [setTheme]);

  return (
    <div className="nhc-container-left nhc-container-right fourohfour">
      <SEO title="404"/>
      <h1 className="visually-hidden">404: Page Not Found</h1>
      <div className="fourohfour-layout">
        <div>
          <div className="fof-title">
            <FourOhFour/>
          </div>
          <p>Page not Found</p>
          <Link to="/" className="fourohfour-button">
            Go Home
          </Link>
        </div>

        <div className="fourohfour-graphic">
          <FourOhFourGraphic/>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
